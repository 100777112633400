import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/real-time-logging-with-tail",
  "date": "27th August 2024",
  "title": "Real Time Logging with the Tail Command",
  "summary": "An introduction to Skpr's latest feature for debugging applications in real time.",
  "author": "Nick Schuch",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "announcement"
  }, {
    "name": "logging"
  }, {
    "name": "debugging"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Overview`}</h2>
    <p>{`Developers need tooling that enables fast feedback loops when debugging application issues.`}</p>
    <p>{`They need to be able to execute a set of steps and see the results in real time. Anything less leads to lots of waiting
and less time spent debugging to get to the root cause.`}</p>
    <p>{`One tool to solve this problem recently shipped in Skpr v0.29.0.`}</p>
    <p>{`This feature is called `}<strong parentName="p">{`Real Time Logging`}</strong>{` and is implemented as the "tail" command on the Skpr Command Line Interface (CLI).`}</p>
    <h2>{`Video`}</h2>

    <YouTube videoId="K75dNOfiKBI" opts={{
      height: '420',
      width: '100%'
    }} mdxType="YouTube" />
    <p>{`In this video I will take you through:`}</p>
    <ul>
      <li parentName="ul">{`Skpr's logging solutions`}</li>
      <li parentName="ul">{`What is the the debug feedback loop?`}</li>
      <li parentName="ul">{`How does Real Time Logging work?`}</li>
      <li parentName="ul">{`A demo of how you can use the tail command`}</li>
    </ul>
    <h2>{`Logging Options`}</h2>
    <p>{`Skpr provides a full suite of logging solutions for development teams.`}</p>
    <ul>
      <li parentName="ul">{`Dashboards - A curated experience for reviewing errors, warnings and cron logging events.`}</li>
      <li parentName="ul">{`Insights - A console for executing queries across large data sets of logs.`}</li>
      <li parentName="ul">{`Tail - Our latest feature for debugging applications live.`}</li>
    </ul>
    <h2>{`The Debug Feedback Loop`}</h2>
    <p>{`We had a very important reason for implementing our tail command. That reason was “The Debug Feedback Loop”.`}</p>
    <p>{`Dashboards and Insights rely on infrastructure tooling which batch and send logs at intervals (typically every 60 seconds).`}</p>
    <p>{`When debugging an issue live (form submission failure), we need to be able to perform that action (e.g. submit a form) and
see the result in real time to be able to understand the interactions currently taking place in our applications. We then
take that information and start performing more actions until we finally understand the root cause. We want this loop to
go fast (just like our applications).`}</p>
    <p>{`Anything less than real time is unacceptable.`}</p>
    <h2>{`How does it work?`}</h2>
    <p>{`Skpr is built on top of Kubernetes, a container native platform for managing applications.`}</p>
    <p>{`One feature of Kubernetes is its APIs for streaming logging data.`}</p>
    <p>{`Skpr’s API coordinates these API calls across multiple instances of your application (e.g. three instances or in Kubernetes terms, Pods).`}</p>
    <p>{`We then expose a number of streams that developers can tail using our API and command line tooling.`}</p>
    <h2>{`What can I stream from the tail command?`}</h2>
    <p>{`You can stream every log in your application stack.`}</p>
    <p>{`If you have deployed Drupal then you can stream Nginx and FPM logs.`}</p>
    <p>{`If you have deployed Node or Go applications then you can stream Web and App logs.`}</p>
    <p>{`But not only that...`}</p>
    <h2>{`You can also stream Daemons!`}</h2>
    <p>{`Daemons are a feature that we recently shipped in v0.28.0.`}</p>
    <p>{`This feature allows developers to deploy long running background tasks, which supplement the primary running application.`}</p>
    <p>{`Examples of this include:`}</p>
    <ul>
      <li parentName="ul">{`Cache invalidators`}</li>
      <li parentName="ul">{`Email processors`}</li>
      <li parentName="ul">{`PDF generation`}</li>
    </ul>
    <p>{`All these tasks print logs. Logs that need to be seen when debugging an application live.`}</p>
    <h2>{`How do I use the tail command?`}</h2>
    <p>{`The tail command can be executed in the following ways:`}</p>
    <pre><code parentName="pre" {...{}}>{`# Tail logs for production using the default stream.
$ skpr logs tail prod

# Discover the streams that you can tail for production.
$ skpr logs list prod

# Tail multiple streams for production.
$ skpr logs tail prod nginx fpm
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`Real Time Logging continues our efforts to bolster how developers can debug applications on Skpr.`}</p>
    <p>{`If you have any questions or would like a demo, `}<a parentName="p" {...{
        "href": "#request-a-demo"
      }}>{`please contact the Skpr team`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      